import type { AxiosInstance } from 'axios'
import { organisations } from './organisations'
import { products } from './products'
import { invitations } from './invitations'
import { featureFlag } from './featureFlag'
import { twoFactorAuthenticator } from './twoFactorAuthenticator'
import { roles } from './roles'
import { permissions } from './permissions'
import { users } from './users'
import { directors } from './directors'
import { countries } from './countries'

export default function coreApi(axios: AxiosInstance) {
  return {
    organisations: organisations(axios),
    products: products(axios),
    invitations: invitations(axios),
    featureFlag: featureFlag(axios),
    twoFactorAuthenticator: twoFactorAuthenticator(axios),
    roles: roles(axios),
    permissions: permissions(axios),
    users: users(axios),
    directors: directors(axios),
    countries: countries(axios),
  }
}
