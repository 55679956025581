import type { AxiosInstance } from 'axios'
import { type GetCollectionOfCountriesResponse } from '@/types/apiResponse/countries.response'

export const countries = (axiosInstance: AxiosInstance) => {
  return {
    getCollectionOfCountries(query?: string) {
      return axiosInstance.get<GetCollectionOfCountriesResponse>(`/countries`, {
        params: { query },
      })
    },
  }
}
