import type { AxiosInstance } from 'axios'
import type {
  CreateNewBeneficiaryPayload,
  ExportVendorsPayload,
  GetBeneficiariesQueryParams,
  GetBeneficiarySchemaPayload,
  UpdateBeneficiaryPayload,
  VerifyBeneficiariesPayload,
} from '~/types/apiPayload/beneficiaries.payload'
import type {
  GetBeneficiariesResponse,
  GetBeneficiaryByIdResponse,
  GetBeneficiarySchemaResponse,
  SearchBeneficariesResponse,
} from '~/types/apiResponse/beneficiaries.response'

export const beneficiaries = (axiosInstance: AxiosInstance) => {
  return {
    getBeneficiaryByOrganisationId(
      organisationId: string,
      params: GetBeneficiariesQueryParams = {},
    ) {
      return axiosInstance.get<GetBeneficiariesResponse>(
        `/Beneficiaries/v2/${organisationId}`,
        {
          params: { ...params },
        },
      )
    },

    searchBeneficaries(searchTerm: string, organisationId: string) {
      return axiosInstance.get<SearchBeneficariesResponse>(
        `/beneficiaries/search`,
        {
          params: {
            query: searchTerm,
            orgId: organisationId,
          },
        },
      )
    },

    getBeneficiaryById(beneficiaryId: string, organisationId: string) {
      return axiosInstance.get<GetBeneficiaryByIdResponse>(
        `/beneficiaries/${organisationId}/${beneficiaryId}`,
      )
    },

    validateBeneficiary(payload: VerifyBeneficiariesPayload) {
      return axiosInstance.post('/beneficiaries/v2/validate', { ...payload })
    },

    createNewBeneficiary(payload: CreateNewBeneficiaryPayload) {
      return axiosInstance.post('/beneficiaries/v2/create', { ...payload })
    },

    updateBeneficiary(
      params: {
        organisationId: string
        beneficiaryId: string
      },
      payload: UpdateBeneficiaryPayload,
    ) {
      const { organisationId, beneficiaryId } = params
      return axiosInstance.patch(
        `/Beneficiaries/${organisationId}/beneficiary/${beneficiaryId}`,
        { ...payload },
      )
    },

    exportVendors(payload: ExportVendorsPayload) {
      return axiosInstance.get(`/beneficiaries/export`, {
        params: {
          ...payload,
        },
      })
    },

    fetchBeneficiarySchema(payload: GetBeneficiarySchemaPayload) {
      return axiosInstance.post<GetBeneficiarySchemaResponse>(
        '/beneficiaries/generate-beneficiary-schema',
        { ...payload },
      )
    },
  }
}
