import type { AxiosInstance } from 'axios'
import type {
  ValidatePaymentMethodsPayload,
  ValidateBulkBillsPaymentMethodsPayload,
} from '~/types/apiPayload/payments.payload'
import type {
  GetSupportedCountries,
  GetSupportedPaymentProvidersResponse,
  MultipleBillsValidationResponse,
  ValidatePaymentResponse,
} from '~/types/apiResponse/payments.response'

export const payments = (axiosInstance: AxiosInstance) => {
  return {
    validatePaymentMethods(payload: ValidatePaymentMethodsPayload) {
      return axiosInstance.post<ValidatePaymentResponse>('/payments/validate', {
        ...payload,
      })
    },

    validateBulkBillsPaymentMethods(
      payload: ValidateBulkBillsPaymentMethodsPayload,
    ) {
      return axiosInstance.post<MultipleBillsValidationResponse>(
        '/payments/validate/multiple',
        payload,
      )
    },

    getSupportedPaymentProviders() {
      return axiosInstance.get<GetSupportedPaymentProvidersResponse>(
        '/OpenBanking/SupportedPaymentProviders',
      )
    },

    getSupportedCountries() {
      return axiosInstance.get<GetSupportedCountries>(
        '/payments/SupportedCountries',
      )
    },
  }
}
